aside {
    width: 260px;
    height: 100vh;
    overflow: hidden;
    background: $reallyDarkGrey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0px 20px 20px 0px;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: 0.4s;
    transform: translateX(-40px);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.3);

    &.mounted {
        transform: translateX(0px); }

    .top, .bottom {
        display: flex;
        flex-direction: column;

        a {
            background: transparent;
            color: white;
            font-size: 14px;
            padding: 20px;
            text-decoration: none;
            opacity: 0.7;
            position: relative;
            display: flex;
            align-items: center;
            transition: opacity 0.2s;
            transition: 0.3s;

            &:hover {
                opacity: 1; }

            &:last-child {
                font-weight: bold;
                font-size: 22px;

                img {
                    display: inline-block;
                    height: 23px;
                    margin-right: 8px;
                    left: -2px; } }

            svg {
                margin-right: 20px; } } }

    .bottom {
        margin-bottom: 30px; }

    .top {
        a {
            &:not(:first-child) {
                &.active {
                    border-right: 5px solid $blue;
                    background: #333;
                    opacity: 1; } }

            &:first-child {
                margin: 10px 0;
                opacity: 1;


                img {
                    display: block;
                    width: 100%;
                    max-width: 156px; } } } }

    .user-button {
        display: none;
        float: none; } }

@media(max-width: 500px) {
    aside {
        width: 80%;
        margin-left: -81%;

        &:not(.active) {
            box-shadow: 0px 0px 0px transparent; }

        &.active {
            margin-left: 0; }

        .user-button {
            display: block; } } }

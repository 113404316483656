$loadingColour: rgba(255,255,255,0.15);

@keyframes loading-pulse {
    0% {
        opacity: 1; }

    50% {
        opacity: 0.5; }

    100% {
        opacity: 1; } }

.academyPost {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &.loading {
        .academyPost-image {
            background-color: $loadingColour;
            animation: loading-pulse infinite 1s; }

        .academyPost-details {
            h3 a {
                color: transparent;
                background: $loadingColour;
                width: 180px;
                display: block;
                animation: loading-pulse infinite 1s;
                animation-delay: 0.2s; }

            .loading-lines {
                list-style: none;
                padding: 0px;
                margin: 0px;
                margin-top: 15px;

                li {
                    width: 70%;
                    background: $loadingColour;
                    height: 20px;
                    margin-bottom: 8px;
                    animation: loading-pulse infinite 1s;
                    animation-delay: 0.2s;

                    &:nth-child(2) {
                        width: 75%;
                        animation-delay: 0.3s; }

                    &:nth-child(3) {
                        width: 67%;
                        animation-delay: 0.4s; }

                    &:nth-child(4) {
                        animation-delay: 0.5s; } } }

            .button {
                background: $loadingColour;
                padding: 7px;
                color: transparent;
                animation: loading-pulse infinite 1s;
                animation-delay: 0.6s; } } }

    &-image {
        display: block;
        width: 100%;
        min-height: 160px;
        padding-bottom: 70%;
        background-size: cover;
        background-position: center;
        transform: scale(1.05);
        transition: transform 1.5s;

        &-cont {
            width: 30%;
            min-width: 200px;
            max-width: 360px;
            border-radius: 8px;
            overflow: hidden;

            &:hover {
                .academyPost-image {
                    transform: scale(1); } } } }

    &-details {
        width: 65%;
        max-width: 800px;
        margin: 0 0 0 40px;

        h2, h3 {
            a {
                color: black;
                text-decoration: none; } }

        .button {
            color: black;
            text-decoration: none; } }

    &-cats {
        display: flex;
        flex-wrap: wrap;

        h5 {
            margin: 0 2.5px 5px; } }

    &-excerpt {
        font-size: 16px;
        line-height: 1.5; }

    &.is-category {
        width: 45%;
        display: block;
        margin-right: 30px;

        .academyPost-details {
            width: auto;
            display: block;
            margin: auto; }

        .academyPost-image-cont {
            width: 100%;
            max-width: none;
            margin-bottom: 24px; } } }





@media(max-width: 500px) {

    .academyPost {
        flex-direction: column;

        &-image {
            padding-bottom: 60%;

            &-cont {
                width: 100%;
                max-width: auto;
                margin: 0 0 20px; } }

        &-details {
            width: 100%;
            margin: 0; }

        &-excerpt {
            font-size: 14px; } } }


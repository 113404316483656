.singleRegistration-features-outer:has(.singleRegistration-promotions) {
    display: grid;
    grid-template-columns: 1fr calc(20% + 10px);
    gap: 20px; }

.singleRegistration-features {
    ul {
        list-style: none;
        margin: 30px 0 0;
        padding: 0;
        max-width: 1800px;
        display: flex;
        flex-wrap: wrap;

        @supports(display: grid) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px; }

        li {
            width: 25%;
            margin: 15px;

            @supports(display: grid) {
                width: 100%;
                margin: 0; } }

        &.no-features {
            display: block !important; } }

    &-free, &-paid {
        &-title {
            padding: 30px 0 0;
            display: flex;
            cursor: pointer;
            width: max-content;

            h3 {
                margin: 0; }

            .icon {
                padding-left: 5px;
                transition: transform .3s;
                opacity: 0.5;
                align-self: center;

                &.open {
                    transform: rotate(90deg); }

                svg {
                    display: block; } } } } }

.singleRegistration-promotions {
    border-left: 1px solid #eee;
    padding-left: 20px;

    &>h5 {
        text-transform: uppercase;
        margin-top: -5px;
        margin-bottom: 15px;
        opacity: 0.3; }

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        display: grid;
        grid-template-rows: 1fr;
        gap: 20px; } }

@media(max-width: 1730px) {
    .singleRegistration-features-outer:has(.singleRegistration-promotions) {
        grid-template-columns: 1fr calc(24.5% + 10px);

        .singleRegistration-features ul {
            grid-template-columns: 1fr 1fr 1fr; } } }

@media(max-width: 1300px) {
    .singleRegistration-features ul {
        grid-template-columns: 1fr 1fr; } }

@media(max-width: 500px) {
    .singleRegistration-features {
        ul {
            display: block !important;

            li {
                margin-top: 24px; } } }

    .singleRegistration-features-outer:has(.singleRegistration-promotions) {
        display: flex;
        flex-direction: column; }

    .singleRegistration-promotions {
        border: none;
        padding: 0px;
        margin-top: 30px; } }

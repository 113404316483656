.academyList {
    display: flex;
    flex-direction: column;

    .academyPost {
        padding: 30px 0;
        border-bottom: 1px solid $midGrey;

        &:first-child {
            padding-top: 0; }

        &:last-child {
            padding-bottom: 0;
            border: 0; } }

    &-mobileButton {
        display: none; } }





@media(max-width: 500px) {

    .academyList {
        &-mobileButton {
            display: block;
            margin: 10px 0 0;

            .button {
                width: 100%;
                justify-content: center; } } } }

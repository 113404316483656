.singleRegistration-firmwareUpdates {
    ul {
        list-style: none;
        margin: 30px 0 0;
        padding: 0;

        li {
            display: flex;
            justify-content: center;
            border: 1px solid $midGrey;
            margin: 0 0 20px;
            padding: 20px;

            &:last-child {
                margin: 0; } } }

    &-icon {
        width: 46px;
        height: 46px;
        margin: 0 20px 0 0;
        flex-shrink: 0;
        background-image: url('/assets/images/Atom-OS-10.jpg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat; }

    &-text {
        width: 100%;
        padding: 0 50px 0 0;

        h4 {
            margin: 0;
            font-weight: bold; }

        .copy {
            margin-top: 10px;

            ul {
                margin: 10px 0px; }

            li {
                display: list-item;
                padding: 0px;
                margin: 0px;
                border: none; } } }

    &-links {
        min-width: 180px;
        display: flex;
        flex-direction: column;
        text-align: center;

        .button {
            justify-content: center;
            text-align: center; }

        .smallLink {
            font-size: 13px;
            color: $grey;
            text-decoration: underline;
            padding: 10px 10px 0;

            &:hover {
                text-decoration: none; } } }

    &-none {
        width: 100%;
        padding: 30px;
        background-color: $lightGrey;
        border: 1px solid $midGrey; } }




@media(max-width: 500px) {
    .singleRegistration-firmwareUpdates {
        ul {
            li {
                display: block;
                border-radius: 4px;

                // &:last-child
 } }                //     margin: 0

        &-icon {
            // width: 46px
            // height: 46px
            margin: 0 !important;
            // flex-shrink: 0
            // background-image: url('/assets/images/Atom-OS-10.jpg')
            // background-size: contain
            // background-position: center
            // background-repeat: no-repeat
            display: inline;
            float: left; }

        &-text {
            width: calc(100% - 46px);
            // padding: 0 50px 0 0
            display: inline-block;
            // align-items: center

            h4 {
                margin: 8px 0 32px 12px;
 } }                // font-weight: bold

        &-links {
            .button {
                justify-content: center;
                text-align: center; }

            .smallLink {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px !important;
                color: black;
                border: 1px solid #262626;
                text-decoration: none !important;
                padding: 8px 10px;
                margin-top: 16px;
                border-radius: 4px;
                font-weight: bold; } }


        &-none {
            width: 100%;
            padding: 30px;
            background-color: $lightGrey;
            border: 1px solid $midGrey; } } }

$copy: 'Basis Grotesque Pro';

$green: #42D7B6;
$darkGreen: #2EB34A;
$red: #ED2324;
$lilac: #8475FF;
$purple: #8E0070;
$blue: #1BAFED;
$lightBlue: #87d4f5;
$yellow: #FBD834;
$grey: #707070;
$lightGrey: #fafafa;
$midGrey: #CBCBCB;
$darkGrey: #262626;
$reallyDarkGrey: #151515;
$brightGreen: #051108;

.auth-main {
    min-height: 100vh;
    color: white;
    background: black;

    &.mounted .auth-inner {
        opacity: 1;

        .logo-main, .inner {
            opacity: 1;
            transform: translateY(0px); } }

    .auth-inner {
        opacity: 0;
        transition: 1s;
        min-height: inherit;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;

        .logo-main, .inner {
            opacity: 0;
            transition: 1s;
            transform: translateY(-20px);
            transition-delay: 0.3s;

            &.inner {
                transition-delay: 0.6s; } }

        .container-narrow {
            padding-top: 0px;
            padding-bottom: 50px; } }

    .logo-main {
        display: flex;
        justify-content: center;
        z-index: 99;

        img {
            display: block;
            width: 200px;
            height: auto;
            align-self: center; } }

    .inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index: 5;

        .form-wrap {
            max-width: 500px;
            margin: auto;
            margin-top: 30px;
            border-radius: 8px;
            background: #3d3d3db6;
            backdrop-filter: blur( 6.0px );
            padding: 30px;
            box-shadow: 0px 5px 20px rgba(0,0,0,0.4);

            .form-container {
                margin: auto; }

            &.register {
                max-width: 650px;
                padding: 0;

                .form-container {
                    padding: 30px 30px 54px;

                    .react-select__menu, .react-select__menu-list {
                        max-height: 200px !important; } }

                .top {
                    text-align: center;
                    margin-bottom: 24px; } }

            .form-slider-slideshow {
                .flickity-viewport {
                    transition: height 0.2s; }

                .flickity-page-dots {
                    margin-bottom: 44px;

                    .dot {
                        background: white;
 } } } } } }                        // pointer-events: none





@media(max-width: 500px) {

    .auth-main {
        height: auto;

        .vertical-20 {
            padding-top: 0px;
            display: flex;
            flex-direction: column;

            .logo-main {
                margin: 100px 0 0; } }

        .auth-inner {
            height: auto;

            &-mobile {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background-size: cover; }

            .container-narrow {
                padding-bottom: 10px; } }

        .inner {
            padding: 0 20px;

            .form-wrap {
                padding: 20px;

                &.register {
                    .form-container {
                        padding: 20px 20px 44px; } } } } } }

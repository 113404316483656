.auth-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    z-index: 3;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    max-width: none;

    &-image {
        height: 90px;
        padding: 20px 0;

        img {
            height: 100%;
            transform: scale(1);
            transition: 0.2s; } }

    a {
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: 0.2s;

        svg {
            margin-right: 8px;
            margin-top: -2px;
            transition: 0.2s; }

        &:hover {
            opacity: 0.9;

            svg {
                margin-right: 12px; }

            img {
                transform: scale(1.04); } } } }





@media(max-width: 500px) {

    .auth-header {
        height: 70px;

        &-image {
            height: 70px; } } }

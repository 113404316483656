.productTile-parent {
    transition: 0.2s;
    transform: translateY(0px);

    &:hover {
        transform: translateY(-5px);

        .productTile {
            box-shadow: 0px 5px 15px rgba(0,0,0,0.1); } } }

.productTile {
    width: 100%;
    border: 1px solid #e0e0e0;
    background-color: $lightGrey;
    box-shadow: 0px 0px 0px transparent;
    overflow: hidden;
    transition: 0.2s;

    > a {
        color: black;
        text-decoration: none;
        display: block; }

    &-image {
        display: block;
        width: 100%;
        padding-bottom: 70%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transform: scale(1.02);
        transition: transform 1.5s;

        &-cont {
            width: 80%;
            margin: 30px auto 15px;
            overflow: hidden;

            &:hover {
                .academyPost-image {
                    transform: scale(1); } } } }

    &-text {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding: 0 30px 30px;

        h4 {
            font-weight: 700;
            margin: 0 0 5px; } }

    &-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > div {
            margin: 2px 0;
            color: #999;
            font-size: 14px;
            word-break: break-all;

            &.updateAvailable {
                background-color: lighten($lightBlue, 8%);
                border-radius: 4px;
                padding: 7px 10px;
                margin-top: 10px;
                color: black;
                font-size: 14px; } } } }


@media(max-width: 500px) {

    .productTile {
        &-image {
            &-cont {
                width: 100%;
                margin: 10px auto 15px;
                padding: 0 10px; } }

        &-text {
            overflow: hidden;
            padding: 0 10px 10px; }

        &-details {
            div {
                color: black;
                font-size: 12px;
                margin: 0 0 5px;

                span {
                    display: block;
                    color: $grey;
                    font-weight: bold;
                    margin: 0 0 5px; }

                &:not(:first-child) {
                    display: none; } } } } }

.user-button {
    &-menu {
        opacity: 0;
        position: absolute;
        top: 135%;
        left: 50%;
        transform: translateX(-50%) scale(0.8);
        transform-origin: top center;
        pointer-events: none;
        transition: 0.2s;
        width: 110%;
        background: $reallyDarkGrey;
        border-radius: 4px;
        text-align: left;

        &.visible {
            opacity: 1;
            transform: translateX(-50%) scale(1);
            pointer-events: all; }

        &:before {
            content: '▲';
            position: absolute;
            color: $reallyDarkGrey;
            left: 50%;
            top: -13px;
            transform: translateX(-50%); }

        ul {
            list-style: none;
            margin: 0;
            padding: 10px 0;

            li {
                color: white;

                button {
                    appearance: none;
                    color: inherit;
                    font-size: 14px;
                    border: none;
                    background: none;
                    padding: 7px 16px;
                    cursor: pointer;
                    width: 100%;
                    text-align: left;
                    transition: 0.1s;
                    background: transparent;

                    &:hover {
                        background: rgba(255,255,255,0.1); } } } } } }





@media(max-width: 500px) {

    .user-button {
        &-menu {
            position: relative;
            opacity: 1;
            top: 0;
            left: 0;
            transform: translateX(0) scale(1);
            pointer-events: all;

            &.visible {
                transform: translateX(0) scale(1); }

            ul {
                padding: 0; } } } }

.StripeElement, .existing-card-display {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    margin-top: 20px;

    &.existing-card-display {
        padding: 7px;
        padding-bottom: 4px; }

    img {
        height: 25px;
        display: inline-block; }

    span {
        vertical-align: top;
        margin-left: 15px;
        top: 3px;
        color: #999; }

    .delete-card {
        padding: 10px;
        background: transparent;
        border: none;
        position: absolute;
        top: 0;
        right: 2px;
        z-index: 2;
        font-size: 18px;
        cursor: pointer;
        transition: 0.15s;
        opacity: 0.5;

        &:hover {
            opacity: 1; } } }

.existing-card-options {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    margin-bottom: -10px;
    margin-top: 15px;

    li {
        margin-right: 15px;

        label {
            display: block;
            transition: 0.15s;
            opacity: 0.3;
            cursor: pointer;

            &.selected, &:hover {
                opacity: 1; }

            input {
                margin-left: 0px;
                margin-right: 5px;
                top: 1px; } } } }

.save-details {
    font-size: 14px;
    margin-top: 10px;

    label {
        display: inline-flex;
        align-items: center;

        input {
            margin-right: 7px;
            accent-color: $blue; }

        span {
            color: #666; } } }

.modal > div.ACTIVATE_FEATURE {
    display: block;
    position: relative;
    top: 0px;
    transform: none;
    left: 0px;
    margin: 70px auto;

    &>div {
        display: block;

        h3 {
            margin-bottom: 10px; }

        .ACTIVATE_FEATURE-description {
            opacity: 0.5;
            font-size: 14px;

            * {
                font-size: 14px; }

            ul {
                padding-left: 20px;
                margin-top: 7px;

                li {
                    margin-bottom: 3px; } } }

        .top {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-bottom: 30px;

            .ACTIVATE_FEATURE-icon {
                width: 170px;
                padding-bottom: 70%;
                background-color: black;
                border-radius: 12px;
                background-image: url('/assets/images/header-logo.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-repeat: no-repeat;
                box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);

                &.noImage {
                    background-size: 30%; }

                .ACTIVATE_FEATURE-cont {
                    width: 100%;
                    max-width: 200px; } }

            .ACTIVATE_FEATURE-details {
                margin: 0 0 0 30px;

                .ACTIVATE_FEATURE-description {
                    margin-bottom: 0px; } }

            .ACTIVATE_FEATURE-title {
                color: $blue;
                margin: 0 0 10px; }

            .tabs {
                list-style: none;
                padding: 0px;
                margin: 0px;
                margin-top: 20px;

                li {
                    display: inline-block;
                    margin-right: 7px;

                    button {
                        font-size: 14px;
                        border: 1px solid #ddd;
                        padding: 5px 10px 6px;
                        border-radius: 5px;
                        transition: 0.15s;

                        &:hover {
                            border-color: $blue; }

                        &.active {
                            background: $blue;
                            color: white;
                            border-color: $blue;

                            i:before {
                                transform: translate(-50%, -50%) scale(1); } }

                        i {
                            display: inline-block;
                            width: 14px;
                            height: 14px;
                            border: 1px solid #ddd;
                            background: white;
                            border-radius: 50%;
                            margin-right: 5px;
                            top: 2px;

                            &:before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%) scale(0);
                                border-radius: 50%;
                                background: $blue;
                                width: 8px;
                                height: 8px;
                                transition: 0.15s; } } } } }

            .form-error {
                margin-top: 20px; }

            input[type="text"] {
                @extend .StripeElement; }

            .button-container {
                display: grid;
                grid-template-columns: 1fr auto;
                grid-gap: 10px;

                input[type="text"] {
                    margin-top: 10px; } }

            .coupon-response {
                font-size: 14px;
                display: block;
                margin-top: 10px;

                &.SUCCESS {
                    color: $green;

                    &:before {
                        content: "\f00c"; } }

                &.ERROR {
                    color: $red;

                    &:before {
                        content: "\f071"; } }

                &:before {
                    content: '';
                    font-family: FontAwesome;
                    display: inline-block;
                    margin-right: 5px; } }

            .button {
                margin-top: 10px; } }

        .bottom {
            .ACTIVATE_FEATURE-description {
                margin-bottom: 0px;

                &>*:last-child {
                    margin-bottom: 0px; } } }

        .ACTIVATE_FEATURE-description {
            margin: 0 0 20px; } } }





@media(max-width: 500px) {

    .modal {
        > div {
            &.ACTIVATE_FEATURE {
                > div {
                    .top {
                        flex-direction: column;

                        .ACTIVATE_FEATURE {
                            &-icon {
                                width: 100%;
                                padding-bottom: 60%;
                                margin: 20px 0; }

                            &-details {
                                width: 100%;
                                margin: 0; } }


                        .StripeElement + .button,
                        .existing-card-display + .button {
                            width: 100%;
                            justify-content: center; } } } } } } }

.modal {
    width: 100%;
    height: 100%;
    display: block;
    backdrop-filter: blur(6px);
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    transform: scale(1.05);
    overflow: auto;

    &.visible {
        opacity: 1;
        visibility: visible;
        transform: scale(1); }

    &>div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        box-shadow: 0px 5px 30px rgba(0,0,0,0.2);
        border: 1px solid $grey;
        border-radius: 12px;
        width: 100%;
        max-width: 750px;
        padding: 40px;

        > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h3 {
                margin: 0 0 20px; }

            ol {
                font-size: 14px;
                line-height: 22px; }

            .button {
                margin: 20px 0 0; } }

        &.loading .loader {
            opacity: 1; }

        .loader {
            height: 48px;
            width: 48px;
            margin: 48px 0;
            filter: invert(0.6);
            animation: rotate 1.5s linear infinite;
            position: absolute;
            top: 10%;
            left: 50%;
            z-index: 4;
            transition: 0.3s;
            opacity: 0; }

        @keyframes rotate {
            0% {
                transform: translate(-50%, -50%) rotate(0deg); }
            100% {
                transform: translate(-50%, -50%) rotate(360deg); } }

        &.EDIT_PROFILE, &.REGISTER_PRODUCTS, &.DELETE_USER, &.DELETE_REGISTRATION {
            max-width: 670px;

            > div {
                display: block; } }

        &.REASSIGN_REGISTRATION {
            max-width: 500px;

            form {
                width: 100%;

                .form-input {
                    margin-top: 10px;

                    .fa {
                        position: absolute;
                        top: 48%;
                        left: 15px;
                        transform: translateY(-50%);
                        opacity: 0.3;
                        pointer-events: none;
                        z-index: 2; }

                    input {
                        width: 100%;
                        border: 1px solid #ddd;
                        border-radius: 3px;
                        font-size: 16px;
                        padding: 10px 15px;
                        padding-left: 40px; } }

                .results {
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    background: #fafafa;
                    border: 1px solid #ddd;
                    margin-top: 10px;
                    height: 180px;
                    overflow: auto;

                    label {
                        display: block;
                        border: none;
                        background: white;
                        border-bottom: 1px solid #ddd;
                        padding: 10px;
                        appearance: none;
                        width: 100%;
                        text-align: left;
                        font-size: 14px;
                        transition: 0.15s;
                        color: #999;
                        cursor: pointer;

                        &.selected, &:hover {
                            color: black; } }

                    input {
                        margin: 0px;
                        top: 2px;
                        display: inline-block;
                        margin-right: 5px; } }

                .button-row {
                    &:after {
                        content: '';
                        display: 'table';
                        clear: both; }

                    .button {
                        float: right;
                        border: 1px solid black; } } } }


        &.DOWNLOAD_TOKEN {
            .sub-section {
                border: 3px solid #ddd;
                padding: 20px;
                margin-top: 30px;
                border-radius: 8px; } }

        &.TEXT {
            > div {
                display: block; }

            .copy {
                * {
                    font-size: 16px;
                    line-height: 1.5; }

                &>a[href] {
                    color: $blue;
                    font: inherit; }

                ol, ul {
                    padding-left: 25px;

                    li:not(:last-child) {
                        margin-bottom: 8px; } }

                .download-links {
                    list-style: none;
                    padding: 0px;

                    li {
                        display: inline-block;
                        margin-right: 5px;

                        a {
                            display: flex;
                            align-items: center;
                            background: $blue;
                            color: white;
                            text-decoration: none;
                            padding: 15px 20px;
                            border-radius: 30px;
                            transition: 0.15s;

                            &:hover {
                                background-color: $lightBlue; }

                            img {
                                filter: invert(1);
                                height: 20px;
                                margin-right: 10px;
                                top: -2px; } } } }

                .code {
                    background: #fafafa;
                    padding: 15px;
                    box-shadow: inset 0px 2px 5px rgba(0,0,0,0.1);
                    border-radius: 5px;
                    display: block; } }

            .subtitle {
                font-size: 16px;
                color: #999;
                display: block;
                margin-top: -15px; } }

        &.narrow {
            max-width: 500px;
            text-align: center;

            &>div {
                align-items: center; }

            .buttons {
                margin-top: 30px;

                .button {
                    margin: 0px 5px; } } }

        &.ADD_RAW_ID {
            max-width: 550px;

            &.loading {
                .loader {
                    opacity: 1; }

                .inner {
                    opacity: 0.2;
                    pointer-events: none; } }

            .code {
                background: #fafafa;
                padding: 15px;
                box-shadow: inset 0px 2px 5px rgba(0,0,0,0.1);
                border-radius: 5px;
                display: block;
                margin-top: 15px;
                font-size: 25px;
                width: 100%; }

            .inner {
                opacity: 1;
                transition: 0.3s; }

            .loader {
                height: 48px;
                width: 48px;
                margin: 48px 0;
                filter: invert(0.6);
                animation: rotate 1.5s linear infinite;
                position: absolute;
                top: calc(45% - 48px);
                left: 50%;
                z-index: 4;
                transition: 0.3s;
                opacity: 0; } }

        &.ACTIVATE_FEATURE {
            &.loading >div .top .ACTIVATE_FEATURE-details form {
                opacity: 0.2;
                pointer-events: none; }


            &>div .top .ACTIVATE_FEATURE-details {
                width: calc(100% - 200px);

                .loader {
                    top: 45%; } }

            .ACTIVATE_FEATURE-camera-selector {
                margin-top: 20px;

                .react-select-container {
                    margin-top: 10px; }

                .react-select__input {
                    input {
                        margin-top: 0px !important; } }

                &>input {
                    display: block;
                    width: 100%;
                    margin-top: 10px !important; } }

            .activate-alert {
                background: $red;
                display: grid;
                grid-template-columns: auto 1fr;
                color: white;
                padding: 20px;
                border-radius: 5px;
                gap: 17px;
                margin-top: 15px;

                img {
                    display: block;
                    width: 55px; }

                h4 {
                    font-weight: bold;
                    margin: 0px; }

                p {
                    margin-bottom: 0px;
                    margin-top: 3px; } } }

        &.PROMO {
            .image {
                width: calc(100% + 82px);
                height: 280px;
                margin: -41px;
                margin-bottom: 30px;
                background-size: cover;
                background-position: center; } }

        &.CONNECTPROMO {
            .button-row {
                width: 100%; }

            .success {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                button {
                    width: max-content; } }

            .form-container {
                .form-row {
                    .form-column {
                        .checkbox {
                            .checkbox-inner {
                                span {
                                    border: 1px solid rgba(0, 0, 0, 0.4); } } }

                        .react-select-container {
                            .react-select__control {
                                .react-select__value-container {
                                    .react-select__placeholder {
                                        color: rgba(0, 0, 0, 0.25);
                                        font-size: 16px; } } } } } } } } }

    &-close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer; }

    .form-container {
        h3 {
            margin: 0px; }

        .copy.large {
            margin-top: 0px;
            color: #999; } } }





@media(max-width: 500px) {

    .modal {
        > div {
            width: 95%;
            padding: 20px;

            .loader {
                top: -19px; }

            &.EDIT_PROFILE, &.REGISTER_PRODUCTS {
                top: 0;
                transform: translateX(-50%);
                margin: 50px 0 100px;
 } }                // overflow: hidden

        &-close {
            top: 10px;
            right: 10px; } } }

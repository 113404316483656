.dashboard-hero {
    width: 100%;
    background-color: black;

    &-cont {
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -40px;
            width: 40px;
            height: 100%;
            background-color: black; } }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -40px;
        opacity: 0.6;
        background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 65%, rgb(63, 226, 221) 100%); }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.6;
        background: linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 75%, rgb(63, 226, 221) 100%); } }





@media(max-width: 500px) {

    .dashboard-hero {
        &::after {
            display: none; } } }

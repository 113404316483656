.no-data {
    display: flex;
    border: 2px solid #ddd;
    flex-direction: row;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 3px 12px rgba(0,0,0,0.07);

    .icon {
        padding: 25px 22px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
            display: block;
            left: 2px; } }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #666;
        padding-left: 22px;
        border-left: 1px solid #ddd;

        .h3 {
            margin: 0px;
            color: inherit;
            font-size: 18px;
            margin-top: 2px; }

        .copy {
            opacity: 1;
            font-size: 14px !important; } } }

@media(max-width: 500px) {

    .no-data {
        border: 1px solid #ddd;

        .icon {
            padding: 48px 20px; }

        .content {
            padding: 16px;
            border-left: 1px solid #ddd; } } }


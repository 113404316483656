input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #251f26 inset !important;
	-webkit-text-fill-color: white !important;
	border: 1px solid #333 !important;
	outline: none !important;

	&:focus {
		border: 1px solid #999 !important; } }

.main-container {
	.form-error {
		box-shadow: 0px 2px 10px rgba(0,0,0,0.15); }

	.form-container .form-row .form-column {
		input {
			background: rgba(255,255,255, 1);
			border: 1px solid #ddd;
			box-shadow: none;
			color: $darkGrey;
			position: relative;

			&:focus {
				border-color: $darkGrey; }

			&::placeholder {
				color: #999; }

			&[readonly] {
				background-color: $midGrey;

				&:focus {
					border-color: #ddd; } } }

		p {
			opacity: 0.7;
			line-height: 14px;
			margin: 10px 0; }

		.react-select-container {
			.react-select__control {
				box-shadow: none;
				background: white;
				border-radius: 4px;
				border: 1px solid lightgrey !important;

				&--is-disabled {
					background-color: $midGrey; }

				.react-select__value-container {
					.react-select__input {
						input {
							box-shadow: none;
							opacity: 1;
							color: #444 !important; } }

					.react-select__single-value {
						color: rgba(black, 1) !important; } }

				.react-select__indicators {
					span, svg {
						display: none; }

					.react-select__dropdown-indicator {
						&::after {
							border-top: 6px solid rgba(#444, 0.58) !important; } } }

				&:hover .react-select__dropdown-indicator {
					&::after {
						border-top: 6px solid rgba(#444, 1); } } } }

		.react-datepicker__input-container:after {
			content: "\f073";
			font-family: FontAwesome;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			pointer-events: none;
			color: #aaa;
			font-size: 14px; }

		.react-datepicker__tab-loop {
			top: -70px; }

		.tile-cont {
			.tile {
				background: $lightGrey;
				color: #262626;
				border: 1px solid #ddd;

				&.active {
					background: white;
					box-shadow: none;
					color: black; }

				&:not(.active):hover {
					box-shadow: 0px 3px 10px rgba(0,0,0,0.15);
					background: white;
					border-color: #ddd; } } }

		.button {
			background: black;
			color: white;
			border: 1px solid black;

			&:hover {
				background: white;
				color: black; } } } }

.auth-main {
	.form-container {
		.form-row .form-column {
			.react-datepicker__tab-loop {
				top: -70px; }

			.tooltip {
				.tooltip-inner {
					left: 0;

					&::before {
						left: 75%; } } } }

		.skipForNow {
			position: absolute;
			top: 46px;
			right: 30px;
			cursor: pointer;
			z-index: 1; } } }

.form-error {
	background: white;
	border-radius: 3px;
	padding: 10px;
	color: #444;
	overflow: hidden;
	font-size: 14px;
	padding-left: 45px;
	margin-bottom: 20px;
	box-shadow: 0px 2px 10px rgba(0,0,0,0.4);

	.fa {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		background: $red;
		color: white;
		line-height: 37px;
		width: 35px;
		text-align: center;

		&.fa-check {
			background: $green; } } }

.form-container {
	width: 100%;

	& + .form-container {
		margin-top: 25px; }

	&.loading {
		.loader-block {
			opacity: 0.2;
			pointer-events: none; }

		.loading {
			opacity: 1; } }

	.loading {
		height: 48px;
		width: 48px;
		margin: 48px 0;
		filter: invert(0.6);
		animation: rotate 1.5s linear infinite;
		position: absolute;
		top: calc(45% - 48px);
		left: 50%;
		z-index: 4;
		transition: 0.3s;
		opacity: 0; }

	@keyframes rotate {
		0% {
			transform: translate(-50%, -50%) rotate(0deg); }
		100% {
			transform: translate(-50%, -50%) rotate(360deg); } }

	.loader-block {
		opacity: 1;
		transition: 0.25s; }

	.button {
		border: none;
		padding: 18px; }

	.form-row {
		&.form-row-active {
			.form-column {
				.tooltip {
					z-index: 998; } } }

		&:not(:last-child) {
			margin-bottom: 20px; }

		.form-column {
			width: 100%;

			label {
				opacity: 0.7;
				font-size: 14px;
				display: block;
				margin-bottom: 10px;
				transition: 0.15s;
				pointer-events: none; }

			.tooltip {
				position: absolute;
				top: -6px;
				right: 0;
				padding: 10px 0;
				color: #999;
				font-size: 12px;
				cursor: pointer;

				&:hover .tooltip-inner {
					opacity: 1;
					transform: translateX(-50%) scale(1); }

				.fa {
					display: inline-block;
					margin-right: 5px; }

				.tooltip-inner {
					position: absolute;
					z-index: 90;
					pointer-events: none;
					left: 50%;
					top: 40px;
					background: white;
					width: 280px;
					padding: 15px 20px;
					box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
					border-radius: 5px;
					transition: 0.2s;
					opacity: 0;
					transform-origin: top center;
					transform: translateX(-50%) scale(0.9);

					&:before {
						content: '▲';
						color: white;
						position: absolute;
						bottom: calc(100% - 8px);
						left: 50%;
						transform: translateX(-50%);
						font-size: 20px; } } }

			input {
				width: 100%;
				padding: 12px 15px;
				font-size: 16px;
				border-radius: 4px;
				box-shadow: inset 0px 3px 5px rgba(0,0,0,0.4);
				background: rgba(0,0,0,0.3);
				border: 1px solid rgba(255,255,255,0.1);
				color: white;
				transition: 0.15s;
				position: relative;

				&:focus {
					border-color: rgba(255,255,255,0.5); }

				&::placeholder {
					font-size: 16px;
					color: white;
					opacity: 0.5; } }

			svg {
				display: none; }

			.link {
				margin-top: 0px;
				display: block;
				margin-bottom: 25px;

				a {
					display: inline-block;
					opacity: 0.5;
					text-decoration: none;
					transition: 0.2s;
					color: white;

					&:hover {
						opacity: 1; } } }

			.tile-cont {
				display: flex;
				justify-content: space-between;
				margin-bottom: 24px;

				.tile {
					height: 80px;
					width: 90px;
					font-size: 10px;
					background: #222;
					padding: 10px;
					border-radius: 4px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid rgba(255,255,255,0.1);
					text-align: center;
					cursor: pointer;
					transition: 0.15s;
					box-shadow: 0px 0px 0px transparent;

					&:not(.active):hover {
						transform: translateY(-3px);
						box-shadow: 0px 5px 15px rgba(0,0,0,0.7);
						border-color: transparent;
						background: #444; }

					&.active {
						background: #575757;
						color: white;
						box-shadow: -4px 4px 8px -2px rgba(0,0,0,0.2);
						border: 1px solid #575757; } } }

			.react-select-container {
				.react-select__control {
					background: rgba(#535353, 0.58);
					border-radius: 3px;
					border: none;
					cursor: pointer;
					min-height: 46px;

					.react-select__value-container {
						.react-select__input {
							input {
								box-shadow: none;
								opacity: 1;
								color: white !important; } }

						.react-select__placeholder {
							color: rgba(white, 0.5);
							font-size: 12px;
							padding-left: 6px; }

						.react-select__single-value {
							color: white;
							padding-left: 6px;
							font-size: 16px; } }

					.react-select__indicators {
						span {
							display: none; }

						.react-select__dropdown-indicator {
							content: '';
							width: 0;
							height: 0;
							position: relative;

							&::after {
								content: '';
								width: 0;
								height: 0;
								border-left: 6px solid transparent;
								border-right: 6px solid transparent;
								border-top: 6px solid rgba(white, 0.58);
								transition: 0.2s;
								position: absolute;
								right: 16px;
								top: 50%;
								transform: translateY(-50%); } } }


					&:hover .react-select__dropdown-indicator {
						&::after {
							border-top: 6px solid rgba(white, 1); } } }


				.react-select__menu {
					z-index: 999;

					.react-select__menu-list {
						color: #444;
						background: rgba(white, 0.58);

						.react-select__option {
							font-size: 12px;
							padding-left: 16px;

							&:hover {
								background-color: #f9f9f9;
								cursor: pointer; }

							&--is-selected {
								background: white;
								color: #444;
								font-weight: bold; } } } } }

			.react-datepicker-wrapper {
				width: 100%; }

			.checkbox {
				box-shadow: none;
				opacity: 0.5;
				transition: 0.15s;
				cursor: pointer;
				pointer-events: all;
				display: grid;
				grid-template-columns: auto 1fr;

				&:hover, &.checked {
					opacity: 1; }

				.checkbox-inner {
					display: inline-block;
					margin-right: 7px;

					input {
						opacity: 0;
						width: 1px;
						height: 1px;
						position: absolute;
						top: 0%;
						left: 0%;

						&:checked + span .fa {
							opacity: 1;
							transform: translate(-50%, -50%) scale(1); } }

					span {
						display: inline-block;
						width: 12px;
						height: 12px;
						border: 1px solid rgba(255,255,255,0.2);
						top: 1px;

						.fa {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%) scale(0);
							opacity: 0;
							transition: 0.2s; } } }

				span a {
					color: white; } } }

		&.columns-2 {
			display: flex;

			.form-column {
				width: calc(50% - 8px);

				&:nth-child(1) {
					margin-right: 16px; } } } } }





@media(max-width: 500px) {

	.form-container {
		.form-row {
			&:not(:last-child) {
				margin-bottom: 10px; }

			&:last-child {
				margin-top: 20px; }

			&.columns-2 {
				flex-direction: column;

				.form-column {
					width: 100%;
					margin-bottom: 10px;
					z-index: 2;

					&:last-child {
						margin-bottom: 0;
						z-index: 1; } } }

			.form-column {
				.tile-cont {
					display: grid;
					grid-template-columns: 1fr 1fr;
					gap: 10px;

					.tile {
						width: 100%; } }

				.tooltip {
					.tooltip-inner {
						left: auto;
						right: 0;
						transform: translateX(6px) scale(0.9);

						&::before {
							left: auto;
							right: 10px; } }

					&:hover {
						.tooltip-inner {
							transform: translateX(6px) scale(1); } } } } } } }

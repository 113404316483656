.support-search {
    .tabs {
        background: $lightGrey;
        margin-top: 0;
        padding-top: 40px; }

    .heading {
        padding: 40px;
        padding-top: 50px;
        padding-bottom: 0;
        background: $lightGrey;

        h2 {
            margin: 0px; } }

    .support-sections {
        border-top: 1px solid #ddd;
        padding: 40px;

        .copy {
            font-size: 18px; }

        .search-inner {
            &.customers form {
                grid-template-columns: 3fr 1fr 1fr auto; }

            &.products form {
                grid-template-columns: 2fr 1fr 1fr 1fr auto; }

            form {
                display: grid;
                gap: 20px;
                margin-top: 25px;

                label {
                    display: block;

                    span {
                        display: block;
                        font-size: 14px;
                        color: #999;
                        margin-bottom: 10px; }

                    .fa {
                        position: absolute;
                        top: 48%;
                        transform: translateY(-50%);
                        left: 15px;
                        z-index: 2;
                        opacity: 0.3; }

                    input {
                        &.button {
                            padding: 13px 15px;
                            padding-top: 13.5px; }

                        &[type="text"] {
                            width: 100%;
                            font-size: 18px;
                            padding: 10px;
                            border: 1px solid #ddd;
                            transition: 0.15s;
                            border-radius: 3px;
                            box-shadow: inset 0px 2px 5px rgba(0,0,0,0.05);
                            padding-left: 35px;

                            &:focus {
                                border-color: #999; } } } } }

            .no-data {
                margin-top: 30px;
                opacity: 0.7; } } }

    .search-loading {
        margin: 50px 0 0; }

    .search-table {
        margin: 50px 0 0;

        &::before {
            content: attr(data-results) ' results found.';
            display: block;
            margin: 0 0 20px; }

        &.oneResult {
            &::before {
                content: attr(data-results) ' result found.'; } }

        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0; }

        th, td {
            padding: 14px;
            text-align: left;
            border-top: 1px solid lighten($midGrey, 8%);
            border-bottom: 1px solid lighten($midGrey, 8%);

            a {
                color: black;
                text-decoration: none;

                &:hover {
                    text-decoration: underline; } }

            .button {
                &:nth-child(2) {
                    margin: 0 0 0 10px; } } }

        th {
            padding-top: 16px;
            padding-bottom: 16px;
            background-color: darken($lightGrey, 4%);

            &:first-child {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 99999px;
                    height: 100%;
                    top: -1px;
                    left: -99999px;
                    border-top: 1px solid lighten($midGrey, 8%);
                    border-bottom: 1px solid lighten($midGrey, 8%);
                    background-color: darken($lightGrey, 4%); } }

            &:last-child {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 99999px;
                    height: 100%;
                    top: -1px;
                    right: -99999px;
                    border-top: 1px solid lighten($midGrey, 8%);
                    border-bottom: 1px solid lighten($midGrey, 8%);
                    background-color: darken($lightGrey, 4%); } } }

        tr {
            &:nth-child(even) {
                background-color: darken($lightGrey, 2%);

                td {
                    &:first-child {
                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 99999px;
                            height: 100%;
                            top: -1px;
                            left: -99999px;
                            border-top: 1px solid lighten($midGrey, 8%);
                            border-bottom: 1px solid lighten($midGrey, 8%);
                            background-color: darken($lightGrey, 2%); } }

                    &:last-child {
                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 99999px;
                            height: 100%;
                            top: -1px;
                            right: -99999px;
                            border-top: 1px solid lighten($midGrey, 8%);
                            border-bottom: 1px solid lighten($midGrey, 8%);
                            background-color: darken($lightGrey, 2%); } } } } } } }





@media(max-width: 500px) {

    .support-search {
        .heading {
            padding: 20px;
            padding-top: 50px;
            padding-bottom: 0; }

        .support-sections {
            padding: 40px 20px;
            overflow: hidden;

            .search-inner {
                &.customers, &.products {
                    form {
                        grid-template-columns: 1fr; } } } }

        .search-table {
            thead {
                display: none; }

            th, td {
                padding: 4px 0;
                border-top: 0;
                border-bottom: 0;

                .button {
                    margin: 4px 0 0; } }

            tr {
                display: flex;
                flex-direction: column;
                padding: 20px 0;

                &:nth-child(even) {
                    td {
                        &::before, &::after {
                            display: none !important; } }

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 999px;
                        height: 100%;
                        top: 0;
                        left: -999px;
                        background-color: darken($lightGrey, 2%); }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 999px;
                        height: 100%;
                        top: 0;
                        right: -999px;
                        background-color: darken($lightGrey, 2%); } }

                &:nth-child(even),
                &:nth-child(odd) {
                    td {
                        &[data-column='Date Registered'],
                        &[data-column='Product'],
                        &[data-column='Serial number'],
                        &[data-column='DID'],
                        &[data-column='AID'] {
                            &::before {
                                content: attr(data-column) ':';
                                display: inline-block !important;
                                width: auto;
                                height: auto;
                                padding: 0 5px 0 0;
                                position: relative;
                                border: 0;
                                top: 0;
                                left: 0;
                                background-color: transparent; } } } } } } } }

.tabs {
    width: 100%;
    z-index: 2;
    margin-top: 20px;

    &-cont {
        width: 100%; }

    ul {
        list-style: none;
        margin: 0 0 0 40px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            margin: 0 10px 0 0;

            a {
                display: flex;
                text-transform: uppercase;
                text-decoration: none;
                color: #999;
                font-size: 14px;
                font-weight: bold;
                border: 0;
                outline: 0;
                cursor: pointer;
                background-color: lighten($midGrey, 10%);
                border-radius: 3px 3px 0 0;
                padding: 14px 24px 12px;
                border: 1px solid #e0e0e0;
                border-bottom: 0;
                transition: background-color 0.2s, color 0.2s;
                letter-spacing: 1px;

                &.active {
                    color: black;
                    background-color: white;
                    border-bottom: 1px solid white;
                    margin-bottom: -1px; }

                span {
                    display: inline-block;
                    background-color: $blue;
                    color: white;
                    padding: 3px 4px 2px 5px;
                    margin: 0 0 0 6px;
                    font-size: 10px;
                    border-radius: 3px;
                    top: -1px;
                    align-self: center; } } } } }

@media(max-width: 500px) {
    .tabs {
        width: 100%;
        z-index: 2;
        margin-top: 40px;
        padding: 32px 0;
        background: white;

        section {
            width: 100%;
            margin: 0 auto;
            padding: 0 20px;

            .copy {
                opacity: 0.5; }


            .react-select-container {
                margin-top: 24px;

                .react-select__control {
                    border-radius: 3px;
                    border: none;
                    cursor: pointer;
                    min-height: 46px;
                    border: 0.5px solid #ccc;
                    background: #f9f9f9;

                    .react-select__indicators {
                        span {
                            display: none; }

                        .react-select__dropdown-indicator {
                            content: '';
                            width: 0;
                            height: 0;
                            position: relative;

                            &::after {
                                content: '';
                                width: 0;
                                height: 0;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-top: 6px solid rgba(black, 0.58);
                                transition: 0.2s;
                                position: absolute;
                                right: 16px;
                                top: 50%;
                                transform: translateY(-50%); } } } }

                .react-select__menu {
                    .react-select__menu-list {
                        color: #444;
                        background: rgba(white, 0.58);

                        .react-select__option {
                            padding-left: 16px;

                            &:hover {
                                background-color: #f9f9f9;
                                cursor: pointer; }

                            &--is-selected {
                                background: white;
                                color: #444;
                                font-weight: bold; } } } } } } } }

.main-header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background: black;
    z-index: 3;
    padding: 15px;
    opacity: 1;
    transition: 0.2s;

    &.transparent {
        background: rgba(0,0,0,0.1); }

    &.transparent-scrolled {
        background: black; }

    &-logo {
        display: none; }

    .menuToggle {
        cursor: pointer;
        display: none;

        &.black-logo {
            svg {
                stroke: black; } } }

    .hideMenu {
        width: 100%;
        height: 9999px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        display: none; } }





@media(max-width: 500px) {

    .main-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;

        &.has-scrolled {
            background: white; }

        &.transparent-scrolled {
            background: black; }

        &-logo {
            display: block;

            img {
                display: block;
                width: 200px;
                height: auto; } }

        .menuToggle {
            display: block;

            svg {
                color: white; } }

        .user-button {
            display: none; }

        .hideMenu {
            display: block;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s;

            &.active {
                opacity: 1;
                visibility: visible; } } } }

.singleRegistration {
    &.mounted {
        .singleRegistration-tabs-cont, .singleRegistration-image-cont, .singleRegistration-details {
            opacity: 1;
            transform: translateY(0px); } }

    .singleRegistration-tabs-cont, .singleRegistration-image-cont, .singleRegistration-details {
        transform: translateY(20px);
        opacity: 0;
        transition: 1s;

        &.singleRegistration-details {
            transition-delay: 0.1s; }

        &.singleRegistration-tabs-cont {
            transition-delay: 0.2s; } }

    &-tabs {
        &-cont {
            width: 100%; } }

    &-main {
        background-color: $lightGrey;

        > .button.back {
            margin-top: 15px;
            margin-left: 40px;
            margin-bottom: 5px;

            svg {
                top: -1px; } }

        &-cont {
            display: flex;
            flex-wrap: wrap;
            padding: 0; }

        &::before {
            content: '';
            display: block;
            width: 40px;
            height: 100%;
            background-color: $lightGrey;
            position: absolute;
            top: 0;
            left: -40px; }

        .inner {
            width: 100%;
            background-color: white;
            padding: 40px;
            padding-top: 30px;
            border-top: 1px solid #e0e0e0;

            .copy {
                opacity: 0.5; }

            .singleRegistration {
                &-title {
                    display: grid;
                    grid-template-columns: 1fr auto;

                    .title-inner {
                        padding-right: 30px; } } } } }

    &-image {
        width: 100%;
        padding-bottom: 70%;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;

        &-cont {
            width: 20%;
            max-width: 220px;
            padding-left: 40px;

            img {
                width: 100%;
                display: block; } } }

    &-details {
        width: 80%;
        padding-left: 45px; }

    &-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        h2 {
            margin: 0 25px 0 0;

            &~ .button {
                background: transparent;
                opacity: 0.3;
                top: -2px;

                &:hover {
                    opacity: 1;
                    color: black; }

                &.download {
                    margin-right: 10px;
                    opacity: 1;

                    &:hover {
                        background: black;
                        color: white; } } } }

        .copy {
            margin: 8px 0 0; }

        h3 {
            margin: 0 50px 0 0; }

        &-buttons {
            align-self: flex-start;

            .button {
                margin: 0 10px 0 0;

                &:last-child {
                    margin: 0; } } } }

    &-sections {
        width: 100%;

        &::before {
            content: 'Loading...';
            visibility: hidden;
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1; }

        &-loading {
            &::before {
                visibility: visible;
                opacity: 1; } } } }

.features-alert {
    box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
    padding: 25px;
    margin-bottom: 40px;
    border-radius: 6px;

    p {
        opacity: 0.5;
        margin: 0px;
        margin-bottom: 20px; }

    h3 {
        margin-top: 0px;
        margin-bottom: 8px; }

    div {
        button {
            margin-right: 10px; } } }

@media(max-width: 500px) {

    .singleRegistration {
        &-main {
            &-cont {
                display: block !important;
                overflow: hidden; }

            > .button.back {
                margin-left: 20px; }

            .inner {
                padding: 40px 20px; } }

        &-image {
            &-cont {
                width: 100%;
                padding: 0 20px;
                margin: 0 auto; } }

        &-details {
            width: 100%;
            margin: 0 auto;
            padding: 0 20px; }

        &-title {
            display: grid;
            grid-template-areas: "singleRegistration-title" "singleRegistration-copy" "singleRegistration-buttons";

            h2 {
                margin: 24px auto;
                padding: 0;

                &+ .button {
                    opacity: 0.5;
                    border: none;
                    text-decoration: underline;
                    width: 100%;
                    justify-content: center; } }

            h3 {
                margin: 0 50px 0 0;
                grid-area: singleRegistration-title; }

            .copy {
                grid-area: singleRegistration-copy;
                width: 100%; }

            &-buttons {
                display: flex;
                flex-wrap: wrap;
                position: static !important;
                margin: 24px 0;
                width: 100%;
                grid-area: singleRegistration-buttons;

                .button {
                    margin: 0 0 10px;
                    width: 100%;
                    justify-content: center;
                    white-space: normal;

                    .button-and-subtitle {
                        display: block; }

                    &:last-child {
                        margin: 0; } } } } } }

@mixin blur-element {
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    filter: blur(5px); }

.page-loader {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0px;
    margin: 0px;
    transition: 1s;
    opacity: 0;
    pointer-events: none;
    z-index: 4;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    &.no-logo {
        background: rgba(0,0,0,0.5); }

    &.visible {
        opacity: 1;
        pointer-events: all; }

    &::before {
        @include blur-element;
        box-shadow: rgba(37, 37, 209, 0.25) 0px 30px 60px -12px inset, rgba(226, 21, 21, 0.3) 0px 18px 36px -18px inset, inset 35px 35px 70px rgba(37, 37, 209, 0.25);
        transform: translate(-50%, -50%) rotate(0deg);
        animation: 2s ease infinite loadAnimation; }

    &::after {
        @include blur-element;
        box-shadow: rgba(99, 12, 6, 0.25) 0px 30px 60px -12px inset, rgba(226, 21, 21, 0.3) 0px 18px 36px -18px inset, inset 35px 35px 70px rgba(99, 12, 6, 0.25);
        transform: translate(-50%, -50%) rotate(225deg);
        animation: 2s ease 0.25s infinite loadAnimation; }

    .loading-waves {
        height: 100%;
        width: 100%;
        display: block;
        position: fixed;
        top: 0;
        left: 0;

        &::before {
            @include blur-element;
            box-shadow: rgba(37, 37, 209, 0.25) 0px 30px 60px -12px inset, rgba(226, 21, 21, 0.3) 0px 18px 36px -18px inset, inset 35px 35px 70px rgba(37, 37, 209, 0.25);
            transform: translate(-50%, -50%) rotate(90deg);
            animation: 2s ease 0.5s infinite loadAnimation; }

        &::after {
            @include blur-element;
            box-shadow: rgba(99, 12, 6, 0.25) 0px 30px 60px -12px inset, rgba(226, 21, 21, 0.3) 0px 18px 36px -18px inset, inset 35px 35px 70px rgba(99, 12, 6, 0.25);
            transform: translate(-50%, -50%) rotate(315deg);
            animation: 2s ease 0.75s infinite loadAnimation; } }

    img {
        height: auto;
        width: 10%;
        min-width: 150px;
        z-index: 99; } }


@keyframes loadAnimation {
    0% {
        height: 75px;
        width: 37.5px;
        opacity: 0; }

    50% {
        height: 180px;
        width: 125px;
        opacity: 1; }

    100% {
        height: 75px;
        width: 37.5px;
        opacity: 0; } }

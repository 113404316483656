.academy-category {
    display: flex;
    background: $lightGrey;
    padding: 90px 0;

    &:nth-child(2) {
        flex-direction: row-reverse;
        background: white;

        .academy-category__slider-section {
            &:before {
                display: block;
                left: 0;
                background: linear-gradient(to right, #fff, rgba(255,255,255,0)); }

            &:after {
                display: none; } }

        .progress-slider {
            width: calc( 100% - 30px );
            margin: 0 0 0 30px; } }

    &:before, &:after {
        content: '';
        position: absolute;
        top: 0px;
        height: 100%;
        width: 70px;
        z-index: 2;
        pointer-events: none; }

    &:before {
        left: 0px;
        background: linear-gradient(to right, $lightGrey, rgba(255,255,255,0)); }

    &:after {
        right: 0px;
        background: linear-gradient(to left, $lightGrey, rgba(255,255,255,0)); }

    &__content {
        width: 30%;
        margin: 0 50px; }

    &__slider-section {
        width: 70%;

        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 70px;
            z-index: 2;
            pointer-events: none; }

        &:before {
            display: none; }

        &:after {
            right: 0;
            background: linear-gradient(to left, #fafafa, rgba(255,255,255,0)); } }

    &__slideshow {
        width: 100%;
        height: 100% !important;

        .progress-slider {
            margin: 30px; }

        .academyPost {
            width: 100%;

            &-cont {
                width: 45%;
                margin: 0 30px 30px 0; } } } }

@media(max-width: 500px) {
    .academy-category {
        display: block;
        background: $lightGrey;
        padding: 30px 0;
        overflow: hidden;

        &:before, &:after {
            display: none; }

        &:nth-child(2) {
            .academy-category__slider-section {
                &:before {
                    display: none; }

                &:after {
                    display: block;
                    right: 0;
                    background: linear-gradient(to left, #fafafa, rgba(255,255,255,0)); }

                .progress-slider {
                    width: calc( 100% - 30px );
                    margin: 0 0 0 30px; } } }

        &__content {
            width: 100%;
            padding: 0 20px;
            margin: 0; }

        &__slider-section {
            width: 100%;
            margin: 30px auto;
            padding: 0 20px;

            &:before, &:after {
                width: 40px; } }

        &__slideshow {
            width: 100%;
            height: 100% !important;

            .flickity-viewport {
                overflow: visible; }

            .progress-slider {
                margin: 30px; }

            .academyPost {
                width: 100%;

                &-cont {
                    width: 90%;
                    margin: 0 20px 60px 0; } } } } }

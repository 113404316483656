.imageCta {
    width: calc( 100% + 60px );
    background-color: black;
    overflow: hidden;
    margin: -30px;
    height: calc(100% + 60px);

    @supports(display: grid) {
        width: auto;
        grid-area: imageCta; }

    a {
        color: white;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            .imageCta-image-cont:after {
                opacity: 0.1; }

            .button {
                background-color: $darkGrey;
                color: white; } } }

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 30px; }

    &-image {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('/assets/images/academyPlaceholder.jpg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &-cont {
            width: calc(100% + 60px);
            padding-bottom: calc(70% + 60px );
            overflow: hidden;
            margin: -40px -30px;
            margin-bottom: 20px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: white;
                opacity: 0;
                transition: 0.3s; } } }

    &-text {
        margin: 0px; }

    .button {
        margin: 20px 0 0; }

    h3 {
        width: 95%; }

    .loading-lines {
        list-style: none;
        padding: 0px;
        margin: 0px;
        margin-top: 15px;

        li {
            width: 70%;
            background: $loadingColour;
            height: 40px;
            margin-left: 30px;
            margin-bottom: 30px;
            animation: cta-loading-pulse infinite 1s;
            animation-delay: 0.2s;

            &:nth-child(1) {
                width: 85%;
                padding-bottom: 60%;
                margin-top: 30px;
                margin-left: 0; }

            &:nth-child(2) {
                width: 75%;
                animation-delay: 0.3s; }

            &:nth-child(3) {
                height: 50px;
                width: 25%;
                animation-delay: 0.4s; } } } }

@keyframes cta-loading-pulse {
    0% {
        opacity: 1; }

    50% {
        opacity: 0.5; }

    100% {
        opacity: 1; } }

@media(max-width: 500px) {
    .imageCta {
        &-image {
            &-cont {
                width: 80%;
                padding-bottom: 50%; } }

        h3 {
            width: 100%; }

        .button {
            width: 100%;
            justify-content: center;
            padding-top: 18px;
            padding-bottom: 15px; } } }

.singleRegistration-productManuals {
    ul {
        list-style: none;
        margin: 30px 0 0;
        padding: 0;

        li {
            display: flex;
            border: 1px solid #ddd;
            margin: 0 0 10px;

            &>a {
                width: 100%;
                display: block;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                color: black;
                padding: 15px 20px;
                transition: 0.2s;

                &:hover {
                    background-color: $lightGrey; }

                > div {
                    display: flex;
                    align-items: center;

                    svg {
                        margin: 0 10px 0 0; } } }

            &:last-child {
                margin: 0; } } }

    &-none {
        width: 100%;
        padding: 30px;
        background-color: $lightGrey;
        border: 1px solid $midGrey; } }

@media(max-width: 500px) {
    .singleRegistration-productManuals {
        ul {
            li {
                a {
                    display: block;

                    .button {
                        margin-left: 32px; } } } } } }

.myProductsSlider {
    margin-top: -25px;
    margin-left: -12.5px;
    margin-right: -12.5px;

    .flickity-page-dots {
        position: relative;
        bottom: 0;
        margin: 30px 0 0;

        .dot {
            background-color: black;
            opacity: 0.4;
            margin: 5px;
            transition: 0.2s;

            &.is-selected {
                opacity: 1;
                transform: scale(1.6); } } }

    &-productTile, &-register-cont {
        width: calc( (100% / 3);
        padding: 25px;
        padding-bottom: 10px;
        padding-left: 12.5px;
        padding-right: 12.5px; }

    .productTile {
        height: 100%; }

    &-register {
        width: 100%;
        height: 100%;
        padding: 0px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CBCBCBFF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        background-color: $lightGrey;
        text-align: center;
        transition: 0.15s;

        &:hover {
            background-color: white;

            a {
                color: black;
                transform: scale(0.95); } }

        a {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $grey;
            text-decoration: none;
            cursor: pointer;
            transition: inherit;
            transform: scale(1); }

        &-cont {
            height: 100%;
            min-height: 350px;
            max-height: 500px; }

        i {
            font-size: 60px; }

        h5 {
            margin: 20px 0 0; } }

    &-mobileButton {
        display: none; } }

@media(max-width: 1100px) and (min-width: 500px) {
    .myProductsSlider-productTile, .myProductsSlider-register-cont {
        width: 50%; } }

@media(max-width: 500px) {

    .myProductsSlider {
        margin-left: -10px;
        margin-right: -10px;

        &-productTile {
            width: 75%;
            padding-left: 10px;
            padding-right: 10px; }

        &-register {
            &-cont {
                min-height: auto;
                max-height: inherit;

                .myProductsSlider-register {
                    min-height: auto !important;

                    a {
                        padding: 20px 10px; } } } }

        &-mobileButton {
            display: block;
            margin: 10px 0 0;

            .button {
                width: 100%;
                justify-content: center; } } }

    .dashboard-myProducts {
        .myProductsSlider-register-cont {
            display: none; } } }

.user-button {
    float: right;

    &>button {
        float: right;
        appearance: none;
        background: transparent;
        border: none;
        display: flex;
        flex-direction: row;
        color: white;
        margin-right: -10px;
        transition: 0.2s;

        &:hover, &.menu-visible {
            margin-right: 0px;

            span svg {
                opacity: 1; } }

        img {
            display: block;
            width: 25px;
            height: 25px;
            object-fit: cover;
            object-position: center;
            border-radius: 5px;
            border: 1px solid #999; }

        span {
            font-size: 16px;
            align-self: center;
            margin-left: 10px;

            svg {
                top: 2px;
                display: inline-block;
                margin-left: 3px;
                opacity: 0;
                transition: 0.2s; } } } }





@media(max-width: 500px) {

    .user-button {
        margin: 0 0 20px 6px;
        opacity: 0.7;

        > button {
            float: none;
            margin: 0 0 10px 10px;

            span {
                svg {
                    display: none; } } } } }

.featureTile-container {
    transition: 0.2s;
    transform: translateY(0);

    &:hover {
        transform: translateY(-5px);

        .featureTile {
            box-shadow: 0px 5px 15px rgba(0,0,0,0.1); } }

    &.inactive {
        &:hover {
            transform: translateY(0);

            .featureTile {
                box-shadow: 0px 0px 0px transparent; } }

        .featureTile {
            cursor: auto;

            &-details {
                cursor: auto;

                .button {
                    background: $midGrey;
                    border-color: $midGrey;
                    color: $grey;

                    &:hover {
                        cursor: auto;
                        background: $midGrey !important;
                        color: $grey !important;
                        border-color: $midGrey; } } } } } }


.featureTile {
    background-color: $lightGrey;
    border-radius: 8px;
    border: 1px solid $midGrey;
    box-shadow: 0px 0px 0px transparent;
    cursor: pointer;
    transition: 0.2s;
    display: block;
    text-decoration: none;
    color: black;

    &-image {
        display: block;
        width: 100%;
        padding-bottom: 55%;
        background-color: black;
        background-image: url('/assets/images/header-logo.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 1.5s;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        &.noImage {
            background-size: 40%;
            transform: scale(0.4); }

        &-cont {
            width: 100%;
            background: black;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .new-tab {
                position: absolute;
                z-index: 2;
                background: $blue;
                color: white;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: bold;
                padding: 7px 12px;
                top: 25px;
                left: -6px;
                font-size: 14px;

                &:before {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 7px 6px 0;
                    border-color: transparent darken($blue, 20%) transparent transparent;
                    position: absolute;
                    content: '';
                    top: 100%;
                    left: 0%; } } } }

    &-details {
        padding: 15px;

        h3 {
            font-size: 20px;
            margin: 0; }

        p {
            color: $grey; }

        &.product-activated {
            .button:hover {
                background-color: $darkGreen !important;
                color: white !important;
                border-color: $darkGreen; } } }

    &-mini-banner {
        background: $blue;
        color: white;
        font-size: 14px;
        padding: 6px 10px;
        display: inline-block;
        margin-bottom: 15px;
        border-radius: 4px; }

    .button {
        width: 100%;
        justify-content: center;
        text-align: center;
        white-space: normal;

        &:nth-of-type(2) {
            margin-top: 10px; } }

    &-reactivate-acs {
        background: none;
        border: none;
        width: 100%;
        margin-top: 10px;
        opacity: 0.5;
        transition: 0.1s;

        &:hover {
            color: $blue;
            opacity: 1; } } }

.academy-section {
    &.loading {
        height: 100vh;

        .loader {
            opacity: 1; } }

    .loader {
        height: 48px;
        width: 48px;
        margin: 48px 0;
        filter: invert(0.6);
        animation: rotate 1.5s linear infinite;
        position: absolute;
        bottom: 15vh;
        left: 50%;
        z-index: 4;
        transition: 0.3s;
        opacity: 0; } }

@media(max-width: 500px) {

    .academy-section {
        .loader {
            top: 85vh;
            bottom: auto; }

        section {
            .academy-category {
                &:nth-child(2) {
                    .progress-slider {
                        width: 100%;
                        margin: 0 !important; } } } } } }

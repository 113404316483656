.singleRegistration-warranty {
    .warranty-files {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;

        &-cont {
            width: 100%;
            height: 400px;
            margin: 30px 0 0;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CBCBCBFF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            background-color: $lightGrey;
            text-align: center; }

        &-label {
            display: flex;
            align-items: center;
            color: $grey;

            &-cont {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: 0.2s;

                &.dragOver {
                    background-color: $midGrey;
                    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23262626FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); } }

            svg {
                margin: 0 10px 0 0; }

            > div {
                text-align: left;
                font-size: 18px;

                em {
                    display: inline;
                    color: black;
                    font-style: normal;
                    border-bottom: 1px solid black; }

                span {
                    display: block;
                    font-size: 14px;
                    font-style: italic;
                    text-align: left;
                    margin: 5px 0 0; } } } }

    &-activated {
        width: 100%;
        max-width: 1400px;
        background-color: $lightGrey;
        padding: 30px;
        margin: 30px 0 0;
        border: 1px solid $midGrey;

        a {
            color: black;
            text-decoration: none;
            border-bottom: 1px solid black;
            transition: 0.2s;

            &:hover {
                border-color: transparent; } } }

    &-update {
        height: 20px;
        margin: 20px 0 0; } }





@media(max-width: 500px) {

    .singleRegistration-warranty {
        .warranty-files {
            &-cont {
                height: 250px;

                label {
                    padding: 25px; } } } } }



.masterclassTile {
    border: 1px solid $midGrey;
    border-radius: 8px;
    overflow: hidden;

    &-image {
        display: block;
        width: 100%;
        padding-bottom: 50%;
        background-image: url('/assets/images/academyPlaceholder.jpg');
        background-size: cover;
        background-position: center;
        transform: scale(1.02);
        transition: transform 1.5s;

        &-cont {
            width: 100%;
            margin: 0 auto 20px;
            overflow: hidden;

            &::before {
                content: attr(data-date);
                display: block;
                position: absolute;
                bottom: 10px;
                left: 10px;
                background-color: $purple;
                color: white;
                font-size: 12px;
                border-radius: 4px;
                padding: 3px 6px;
                z-index: 1; }

            &:hover {
                .masterclassTile-image {
                    transform: scale(1); } } } }

    &-text {
        padding: 0 20px 10px;

        h4 {
            font-weight: 700;
            margin: 0 0 10px;

            a {
                color: black;
                text-decoration: none; } }

        > div {
            color: $darkGrey; }

        .button {
            width: 100%;
            margin: 20px 0 0;
            color: $blue;
            justify-content: space-between;

            &:hover {
                color: black;
                opacity: 1; } } } }

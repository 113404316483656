.singleRegistration-data {
    ul {
        list-style: none;
        margin: 25px 0 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            padding: 0 30px 15px 0;
            font-size: 14px;

            span {
                display: flex;
                align-items: center;
                margin: 0 0 6px;
                text-transform: uppercase;
                color: #999;
                letter-spacing: 1px;
                font-size: 12px;
                font-weight: bold;

                svg {
                    margin: 0 0 0 6px; } } } } }

@media(max-width: 500px) {
    .singleRegistration-data {
        max-width: 500px;

        ul {
            display: block !important;

            li {
                width: 100%;
                padding: 0 0 15px;
                text-align: center;

                span {
                    justify-content: center; } } } } }

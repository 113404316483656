.singleUser {
    height: 100%;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        transition: 0.5s; }

    &.loading {
        &:before {
            opacity: 1;
            visibility: visible; } }

    &-nav {
        color: white;
        padding: 0 20px;
        font-size: 14px;

        h5 {
            color: $grey;
            font-size: 12px;
            margin: 40px 0 5px; }

        a {
            margin: 0 !important;
            padding: 0 !important; } }

    .myProducts, .singleRegistration {
        padding-top: 1px;
        margin-left: 260px;
        max-height: calc(100vh - 57px);
        overflow: auto; }

    .myProducts {
        h2 {
            margin: 10px 0 30px; }

        .button.back {
            margin-top: 20px;
            margin-left: 10px;
            margin-bottom: 10px;

            svg {
                top: -1px; } } } }





@media(max-width: 500px) {

    .singleUser {
        .myProducts, .singleRegistration {
            padding-top: 1px;
            margin-left: 0; } } }

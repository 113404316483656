.myProducts {
    min-height: 100%;
    padding: 30px;
    background: #f0f0f0;

    h2 {
        margin: 45px 0 30px; }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        @supports(display: grid) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 15px; }

        li {
            width: 25%;
            margin: 15px;

            @supports(display: grid) {
                width: 100%;
                margin: 0; }

            &.inactive {
                opacity: 0.5; }

            &.myProductsSlider-register-cont {
                width: 25%;
                padding: 0;

                @supports(display: grid) {
                    width: auto; }

                .myProductsSlider-register {
                    background-color: transparent;
                    min-height: 400px;
                    display: flex;
                    justify-content: center;
                    align-items: center; } } } } }





@media(max-width: 500px) {

    .myProducts {
        padding: 20px;

        ul {
            grid-template-columns: 1fr 1fr; } } }


.academy-hero {
    min-height: calc(40vh + 60px);
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: black;
    margin-top: -60px;

    &::before {
        content: '';
        color: white;
        min-height: 100%;
        background: url("../../assets/images/academy-hero-bg.jpg");
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        position: absolute;
        top: -120px;
        left: -40px;
        z-index: -1; }


    &__content {
        text-align: center;
        color: white;
        width: 50%;
        margin: 0 auto;
        padding: 10vh 0;

        p {
          color: #FFFFFFBF; }

        img {
            margin-bottom: 30px;
            width: 90%; } } }





@media(max-width: 500px) {

    .academy-hero {
        min-height: 40vh;
        padding-top: 60px;
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        top: 0px;

        &::before {
            display: none; }


        &__content {
            text-align: center;
            color: white;
            width: 90%;
            margin: 0 auto;
            padding: 10vh 0;

            h3 {
                font-size: 28px; }

            p {
                color: #FFFFFFBF; }

            img {
                margin-bottom: 30px;
                width: 90%; } } } }

.academySlider {
    z-index: 1;
    height: 100%;

    .flickity-page-dots {
        width: auto;
        bottom: 25px;
        right: 0px;

        .dot {
            background-color: white;
            opacity: 0.4;
            margin: 5px;
            transition: 0.2s;

            &.is-selected {
                opacity: 1;
                transform: scale(1.6); } } }

    .container {
        height: auto;
        padding-top: 90px;
        display: flex;
        justify-content: left; }

    &-slideshow {
        &-images {
            width: 350px;
            height: auto;
            margin: 0px;
            margin-right: 40px;

            .academyPost {
                height: auto;

                &.is-selected .academyPost-image {
                    transform: scale(1); } }

            .academyPost-image-cont {
                width: 100%;
                min-width: auto;
                max-width: 650px; }

            .academyPost-details {
                display: none; } }

        &-content {
            width: calc(100% - 390px);
            height: auto;

            .flickity-viewport {
                top: 50%;
                transform: translateY(-50%);
                transition: 0.5s;
                min-height: 220px; }

            .academyPost {
                height: auto; }

            .academyPost-image-cont {
                display: none; }

            .academyPost-details {
                width: 100%;
                margin: 0;
                color: white;

                .academyPost-excerpt {
                    opacity: 0.7; }

                a {
                    color: white;
                    text-decoration: none;

                    &.button {
                        margin-top: 10px;

                        &:hover {
                            color: white;
                            opacity: 0.6; } } } } } } }





@media(max-width: 500px) {

    .academySlider {
        .container {
            flex-direction: column; }

        &-slideshow {
            &-images {
                width: 100%;
                margin: 0 0 20px; }

            &-content {
                width: 100%;

                .flickity-viewport {
                    top: 0;
                    transform: translateY(0);
                    min-height: auto; }

                .academyPost {
                    padding-bottom: 70px; } } }

        .flickity-page-dots {
            right: auto;
            left: 0; } } }

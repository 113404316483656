.dashboard {
    width: 100%;
    height: 100%;

    &.mounted .widget-container > div {
        transform: translateY(0px);
        opacity: 1; }

    .widget-container > div {
        transition: 1s;
        transform: translateY(15px);
        opacity: 0;
        transition-delay: 0.3s;
        overflow: hidden;
        margin: 0 30px 30px 0;

        &.dashboard-imageCta2 {
            box-shadow: none;
            background: transparent;

            .imageCta {
                overflow: hidden;
                border-radius: 5px;

                &:nth-child(2) {
                    margin-top: 60px; } } }

        @supports(display: grid) {
            margin: 0; }

        &:nth-child(2) {
            transition-delay: 0.45s; }

        &:nth-child(3) {
            transition-delay: 0.6s; }

        &:nth-child(4) {
            transition-delay: 0.75s; }

        &.promo-alert {
            margin-bottom: 30px;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 30px;

            img {
                display: block;
                height: 130px;
                width: 220px;
                object-fit: cover;
                object-position: center; }

            h2 {
                margin: 0px; }

            p {
                font-size: 16px;
                margin-top: 8px;
                color: #999;

                a {
                    color: black; } } } }

    > .container {
        background-color: $lightGrey;

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: -40px;
            width: 40px;
            height: 100%;
            background-color: $lightGrey; } }

    &-main {
        display: flex;
        flex-wrap: wrap;

        @supports(display: grid) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 30px;
            grid-template-areas: "myProducts myProducts myProducts imageCta" "academyList academyList academyList masterclassList"; } }

    &-imageCta, &-imageCta2 {
        width: calc( 25% - 60px );

        @supports(display: grid) {
            width: auto; } } }

@media(max-width: 1100px) and (min-width: 501px) {
    .dashboard-hero {
        .academySlider-slideshow-images {
            width: 250px; }

        .academySlider-slideshow-content {
            width: calc(100% - 290px); } }

    .dashboard-main {
        display: block;

        .imageCta .container {
            display: grid;
            grid-template-columns: 1fr 3fr;

            .imageCta-image-cont {
                margin: 0px;
                width: auto;
                margin: 0px; }

            .imageCta-text {
                padding-left: 45px; } }

        .academyPost-image-cont {
            align-self: start; } } }




@media(max-width: 500px) {

    .dashboard {
        &-main {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 30px;
            grid-template-columns: 1fr;
            grid-template-areas: "myProducts" "imageCta" "academyList" "masterclassList"; } } }

.cookie-popup {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 9;
    padding: 20px;
    background: white;
    box-shadow: 0px 5px 25px rgba(0,0,0,0.2);
    max-width: 500px;
    transition: 0.25s;
    opacity: 0;
    pointer-events: none;
    transform-origin: bottom right;
    transform: scale(0.9);

    &.visible {
        opacity: 1;
        transform: scale(1);
        pointer-events: all; }

    h2 {
        margin: 0px;
        font-size: 30px; }

    p {
        margin-top: 5px;
        color: #999;
        font-size: 16px; }

    .buttons-row {
        text-align: right;
        margin-top: 20px;

        .button {
            margin-left: 10px; }

        a {
            color: #999;
            text-decoration: none; } } }

* {
	font-family: $copy;
	position: relative;
	-webkit-tap-highlight-color: transparent;
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
	outline: none; }

body {
    min-width: 1024px; }

h1, .h1, h1 p, .h1 p {
	font-size: 50px;
	font-weight: 700;
	margin: 20px 0px;

	p, a, span {
		font-size: inherit;
		font-family: inherit; } }

h2, .h2, h2 p, .h2 p, h2 a, .h2 a {
	font-size: 35px;
	font-weight: 700;
	margin: 15px 0px;
	line-height: 1.3; }

h3, .h3, h3 p, .h3 p {
	font-size: 24px;
	font-weight: 700;
	margin: 10px 0px;
	line-height: 1.3; }

h4, .h4, h4 p, .h4 p {
	font-size: 20px;
	font-weight: 500;
	margin: 10px 0px;
	line-height: 1.3; }

h5, .h5, h5 p, .h5 p {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px; }

p, .copy {
    font-size: 14px;
    line-height: 22px;

    &.large {
        font-size: 16px;
        line-height: 26px; }

    &.small {
        font-size: 9px;
        line-height: 14px; } }

body {
    @extend .hide-scrollbar; }

button {
    cursor: pointer; }

.vertical-centre {
    top: 50%;
    transform: translateY(-50%); }

.vertical-20 {
    padding-top: 20vh; }

.hide-scrollbar {
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar-track {
		display: none; }

	&::-webkit-scrollbar {
		display: none; }

	&::-webkit-scrollbar-thumb {
		display: none; } }

.main-container {
    width: 100%;
    height: 100vh;
    display: flex;

    &.support {
        main {
            margin-left: 0; } }

    main {
        margin-left: 260px;
        height: 100%;
        width: 100%; } }

.switch-wrapper {
    height: 100%;
    position: relative;
    width: 100%;
    @extend .hide-scrollbar;

    & > div {
        width: inherit;
        height: inherit;
        position: absolute;
        bottom: 0;

        &>div:not(.no-padding-top) {
            padding-top: 57px; } } }

.container {
    padding: 40px;

    &-auth {
        width: 100%;
        max-width: 1620px;
        margin: auto;
        padding: 0 30px; } }

.top {
    z-index: 999; }

.center {
    text-align: center; }

.animate-link {
    margin-left: 8px;
    color: white;
    text-decoration: none;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: white;
        position: absolute;
        left: 0;
        bottom: 0; }

    &:hover {
        &::after {
            animation: 0.4s link-hover ease; } } }

.widget-container {
    &>div {
        box-shadow: 0px 0px 6px rgba(0,0,0,0.05);
        padding: 30px;
        border-radius: 5px;
        background: white;

        &>h3 {
            margin: 0px;
            margin-bottom: 25px;

            a {
                color: $blue;
                font-weight: bold;
                text-decoration: none;
                transition: 0.3s;
                float: right;
                font-weight: 500;
                font-size: 16px;

                &:hover {
                    color: black; }

                svg {
                    top: 2px;
                    margin-left: 5px; } } } } }


@keyframes link-hover {
    0% {
        width: 0%; }
    100% {
        width: 100%; } }





@media(max-width: 500px) {

    body {
        min-width: auto !important; }

    h2, .h2, h2 p, .h2 p, h2 a, .h2 a {
        font-size: 25px; }

    .main-container {
        main {
            margin-left: 0; }

        &.support {
            aside {
                display: block;

                .bottom {
                    position: absolute;
                    bottom: 0; } } } }

    .container {
        padding: 20px;

        &-auth {
            padding: 0 20px; } }

    .widget-container {
        &>div {
            padding: 20px;

            &>h3 {
                a {
                    display: none; } } } } }

.progress-slider {
    background: lightgrey;
    height: 6px;
    border-radius: 10px;

    &::before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background-color: lightgrey;
        position: absolute;
        top: 0;
        left: 12px;
        border-radius: 50%;
        z-index: 1; }

    &::after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background-color: lightgrey;
        position: absolute;
        top: 0;
        right: 12px;
        border-radius: 50%;
        z-index: 1; }

    .rangeslider {
        &::before {
            content: '';
            display: block;
            width: 15px;
            height: 6px;
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 0; }

        &::after {
            content: '';
            display: block;
            width: 15px;
            height: 6px;
            background-color: transparent;
            position: absolute;
            top: 0;
            right: 0; } }

    .rangeslider__handle {
        width: 30px;
        height: 30px;
        background: white;
        border: 2px solid rgba(black,0.5);
        border-radius: 50%;
        transform: translateY(-12px) translateX(-15px);
        transition: background 0.2s border 0.2s;
        cursor: pointer;
        z-index: 2;

        &:hover {
            border: 2px solid rgba(black,0.8);

            &:before {
                background: rgba(black, 0.8); } }

        &:before {
            content: '';
            width: 6px;
            height: 6px;
            background: rgba(black, 0.5);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%; } } }

@media(max-width: 500px) {
    .progress-slider {
        background: lightgrey;
        height: 6px;
        border-radius: 10px;

        &::before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: lightgrey;
            position: absolute;
            top: 0;
            left: 12px;
            border-radius: 50%;
            z-index: 1; }

        &::after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: lightgrey;
            position: absolute;
            top: 0;
            right: 12px;
            border-radius: 50%;
            z-index: 1; }

        .rangeslider {
            &::before {
                content: '';
                display: block;
                width: 15px !important;
                height: 6px;
                background-color: transparent;
                position: absolute;
                top: 0;
                left: 0; }

            &::after {
                content: '';
                display: block;
                width: 15px;
                height: 6px;
                background-color: transparent;
                position: absolute;
                top: 0;
                right: 0; } }

        .rangeslider__handle {
            width: 30px;
            height: 30px;
            background: white;
            border: 2px solid rgba(black,0.5);
            border-radius: 50%;
            transform: translateY(-12px) translateX(-15px);
            transition: background 0.2s border 0.2s;
            cursor: pointer;
            z-index: 2;

            &:hover {
                border: 2px solid rgba(black,0.8);

                &:before {
                    background: rgba(black, 0.8); } }

            &:before {
                content: '';
                width: 6px;
                height: 6px;
                background: rgba(black, 0.5);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%; } } } }

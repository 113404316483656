.dashboard-myProducts {
    width: 75%;

    @supports(display: grid) {
        width: auto;
        grid-area: myProducts; }

    &-intro {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 30px;

        a {
            color: $blue;
            font-weight: bold;
            text-decoration: none;
            transition: 0.3s;

            &:hover {
                color: black; } } } }


.categoryLink {
    background-color: $red;
    border-radius: 4px;
    overflow: hidden;
    margin: 0;
    align-self: flex-start;
    text-transform: uppercase;
    font-size: 13px;

    a {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 3px 6px;
        color: white;
        text-decoration: none;

        &:hover {
            color: white !important; } }

    &.atomos-news {
        background-color: $green; }

    &.ninja-v {
        background-color: $lilac; }

    &.apple-prores, &.prores-raw {
        background-color: $yellow;

        a {
            color: black !important;

            &:hover {
                color: black !important; } } }

    &.my-atomos-rig {
        background-color: $blue; }

    &.post-production {
        background-color: $green;

        a {
            color: black !important;

            &:hover {
                color: black !important; } } }

    &.shogun-7 {
        background-color: $blue; } }





@media(max-width: 500px) {

    .categoryLink {
        border-radius: 3px;
        font-size: 12px; } }

.button {
    display: inline-flex;
    text-decoration: none;
    white-space: nowrap;
    padding: 10px 15px;
    padding-top: 11.5px;
    background: white;
    transition: 0.1s;
    cursor: pointer;
    color: black;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid black;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1px;

    &.disabled {
        pointer-events: none;
        opacity: 0.3 !important; }

    &:hover {
        background: black;
        color: white; }

    &.inverted {
        background: black;
        color: white;

        &:hover {
            background: white;
            color: black; } }

    &.outline {
        border: 1px solid white;

        &:hover {
            background: transparent; } }

    &.grey {
        background: #f0f0f0;
        border-color: #f0f0f0; }

    svg {
        margin: 0 0 0 6px; }

    &.back, &.edit, &.download, , &.register, &.success {
        svg {
            margin: 0 6px 0 0; } }

    &.naked {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        border: 0;
        background-color: transparent;

        &:hover {
            color: black;
            background-color: transparent;
            opacity: 0.6;

            &::before {
                color: black; } }

        &.inverted {
            color: white;

            &:hover {
                color: black; } } }

    &.success {
        background: $darkGreen;
        color: white;
        border-color: $darkGreen;

        &:hover {
            border-color: black; } }

    &.full-width {
        width: 100%;
        text-align: center;
        display: inline-block; }

    &.compact {
        padding: 8px 9px 6px;
        font-size: 11px;

        svg {
            top: 1px; } }

    .subtitle {
        opacity: 0.7;
        display: block;
        letter-spacing: 0px;
        text-transform: none;
        font-weight: 500;
        margin-top: 3px; }

    del {
        display: inline-block;
        margin: 0px 2px;
        opacity: 0.7; } }

@media(max-width: 500px) {

    .button {
        padding: 8px 12px 10px;
        font-size: 14px;

        span {
            font-size: 12px; }

        &.video {
            &::after {
                top: 13px;
                left: 19px; } } } }
